import { object } from 'prop-types';
import * as styles from './index.module.scss';
import { ResourceMainImage } from '../resource-main-image';
import { Wrap } from '../sections-wrap';
import { PracticeParentItem } from './practice-parent';
import { constants } from '../../config';

function BrowseResources({ data }) {
  const practiceParents = data?.allWpPage?.nodes;
  practiceParents?.sort((a, b) => (a.title?.localeCompare(b.title)));

  const practiceParentItem = (databaseId) => {
    let posts = [];
    if (databaseId === constants.familyLawCategoryId) {
      posts = data.relatedPostsFamilyLaw.nodes;
    } else if (databaseId === constants.realEstateCategoryId) {
      posts = data.relatedPostsRealEstate.nodes;
    } else if (databaseId === constants.litigationCategoryId) {
      posts = data.relatedPostsLitigation.nodes;
    }
    return posts;
  };

  return (
    <>
      <ResourceMainImage title="Browse Resources" />
      <div className={styles.pageContainer}>
        <Wrap>
          <main className={styles.main}>
            <article className={styles.entry}>
              <div>
                {practiceParents?.map((practiceParent) => (
                  <PracticeParentItem
                    data={practiceParent}
                    key={practiceParent.title}
                    posts={practiceParentItem(practiceParent?.template?.practiceParentConfiguration
                      ?.eaPracticeParentColoradoConnectedPostCategories?.databaseId)}
                  />
                ))}
              </div>
            </article>
          </main>
        </Wrap>
      </div>
    </>
  );
}

BrowseResources.propTypes = {
  data: object,
};

BrowseResources.defaultProps = {
  data: {},
};

export { BrowseResources };
