import { string, bool } from 'prop-types';

function Icon({ open, className, classNameActive }) {
  return (
    open ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classNameActive}
        viewBox="0 0 44 44"
      >
        <path fillRule="evenodd" d="M0.969678024,16.4589787 C-0.323226008,15.2077686 -0.323226008,13.1845353 0.969678024,11.946636 L4.07814942,8.93840755 C5.37105345,7.68719748 7.46170678,7.68719748 8.74085652,8.93840755 L22,21.7699661 L35.2591435,8.93840755 C36.5520475,7.68719748 38.6427008,7.68719748 39.9218506,8.93840755 L43.030322,11.946636 C44.323226,13.1978461 44.323226,15.2210794 43.030322,16.4589787 L24.3244764,34.5615924 C23.059081,35.8128025 20.9684276,35.8128025 19.6755236,34.5615924 L0.969678024,16.4589787 Z" transform="rotate(-180 22 21.75)" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 44 44"
      >
        <path fillRule="evenodd" d="M0.969678024,16.6127783 C-0.323226008,15.338819 -0.323226008,13.2787996 0.969678024,12.018393 L4.07814942,8.95546951 C5.37105345,7.68151016 7.46170678,7.68151016 8.74085652,8.95546951 L22,22.0203291 L35.2591435,8.95546951 C36.5520475,7.68151016 38.6427008,7.68151016 39.9218506,8.95546951 L43.030322,12.018393 C44.323226,13.2923524 44.323226,15.3523717 43.030322,16.6127783 L24.3244764,35.0445305 C23.059081,36.3184898 20.9684276,36.3184898 19.6755236,35.0445305 L0.969678024,16.6127783 Z" />
      </svg>
    )
  );
}

Icon.propTypes = {
  open: bool,
  className: string,
  classNameActive: string,
};

Icon.defaultProps = {
  open: false,
  className: '',
  classNameActive: '',
};

export { Icon };
