import { array, bool } from 'prop-types';
import { useState } from 'react';
import { Item } from './item';
import { Icon } from './icon';
import { blogPostsFiltered } from '../../helpers';

import * as styles from './index.module.scss';

function PracticeChild({ data, posts, isAllResources }) {
  const practiceChildConfig = data.template?.practiceChildConfiguration;
  const pChildPostCategories = practiceChildConfig?.eaPracticeChildColoradoConnectedPostCategories;
  const pChildPostCategoriesDatabaseId = pChildPostCategories?.databaseId;
  const [open, setOpen] = useState(false);
  let browseData = data?.practiceToPostsConnection?.nodes || data?.allResourcesPerPParent;
  browseData = posts && posts.length > 0 && !isAllResources
    ? posts.filter((post) => post.categories.nodes.map(
      (pp) => pp.databaseId,
    ).includes(pChildPostCategoriesDatabaseId))
    : browseData;
  browseData = posts && posts.length > 0 && isAllResources ? posts : browseData;
  browseData = browseData?.sort((a, b) => (a.title?.localeCompare(b.title)));

  const filterByType = (type) => browseData?.filter(
    (item) => item.categories?.nodes.filter(
      (node) => node.databaseId.toString() === type,
    ).length,
  );

  const QandAs = filterByType(process.env.GATSBY_EA_QANDA_CATEGORY_ID);
  const quizzes = filterByType(process.env.GATSBY_EA_QUIZZES_CATEGORY_ID);
  const checklists = filterByType(process.env.GATSBY_EA_CHECKLISTS_CATEGORY_ID);
  const calculators = filterByType(process.env.GATSBY_EA_CALCULATORS_CATEGORY_ID);
  const reviews = filterByType(process.env.GATSBY_EA_REVIEWS_CATEGORY_ID);
  const blogPosts = blogPostsFiltered(browseData);

  return (
    <div className={styles.practiceChildContainer}>
      <button type="button" onClick={() => setOpen(!open)} className={open ? styles.childTitleActive : styles.childTitle}>
        <Icon
          open={open}
          className={styles.childSvgContainer}
          classNameActive={styles.childSvgContainerActive}
        />
        {data?.template?.practiceChildConfiguration?.eaPracticeShortName || data?.title}
      </button>
      {open && (
      <div>
        {!!QandAs.length && <Item title="Q&As" data={QandAs} />}
        {!!quizzes.length && <Item title="Quizzes" data={quizzes} />}
        {!!checklists.length && <Item title="Checklists" data={checklists} />}
        {!!calculators.length && <Item title="Calculators" data={calculators} />}
        {!!reviews.length && <Item title="Results" data={reviews} />}
        {!!blogPosts.length && <Item title="Blogs" data={blogPosts} />}
      </div>
      )}
    </div>
  );
}

PracticeChild.propTypes = {
  data: array,
  posts: array,
  isAllResources: bool,
};

PracticeChild.defaultProps = {
  data: [],
  posts: [],
  isAllResources: false,
};

export { PracticeChild };
