import { array, string } from 'prop-types';
import { useState } from 'react';
import { Icon } from './icon';

import * as styles from './index.module.scss';

function Item({ title, data }) {
  const [open, setOpen] = useState(false);
  data?.sort((a, b) => (a.title?.localeCompare(b.title)));

  return (
    <div className={styles.articlesContainer}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={open ? styles.articleTitleActive : styles.articleTitle}
      >
        <Icon
          open={open}
          className={styles.articleSvgContainer}
          classNameActive={styles.articleSvgContainerActive}
        />
        {title}
      </button>
      {open && (
      <div className={styles.articles}>
        <ul>
          {data?.map((article) => (
            <li key={article.uri}><a href={article.uri}>{article.title}</a></li>
          ))}
        </ul>
      </div>
      )}
    </div>
  );
}

Item.propTypes = {
  title: string,
  data: array,
};

Item.defaultProps = {
  title: '',
  data: [],
};

export { Item };
