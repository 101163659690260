import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { BrowseResources } from '../components/browse-resources-items/browse-resources';

function BrowseResourcesTemplate({ data }) {
  return (
    <BrowseResources data={data} />
  );
}

export const query = graphql`
query ($familyLawCategories: [Int], $realEstateCategories:[Int], $litigationCategories:[Int]) {
  wpPage(uri: {eq: "/resource-center/browse-resources/"}) {
    ...SeoPage
    title
  }
  relatedPostsFamilyLaw: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {in: $familyLawCategories}}}}}) {
    nodes {
      title
      excerpt
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      uri
      categories {
        nodes {
          databaseId
        }
      }
      featuredImage {
        node {
          gatsbyImage(width:400)
        }
      }
    }
  }
  relatedPostsRealEstate: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {in: $realEstateCategories}}}}}) {
    nodes {
      title
      excerpt
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      uri
      categories {
        nodes {
          databaseId
        }
      }
      featuredImage {
        node {
          gatsbyImage(width:400)
        }
      }
    }
  }
  relatedPostsLitigation: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {in: $litigationCategories}}}}}) {
    nodes {
      title
      excerpt
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      uri
      categories {
        nodes {
          databaseId
        }
      }
      featuredImage {
        node {
          gatsbyImage(width:400)
        }
      }
    }
  }
  allWpPage(filter: {template: {templateName: {eq: "Practice Parent"}}}) {
      nodes {
        title
        databaseId
        wpChildren {
          nodes {
            ... on WpPage {
              title
              databaseId
              template {
                ... on WpTemplate_PracticeChild {
                  templateName
                  practiceChildConfiguration {
                    eaPracticeShortName
                    eaPracticeChildColoradoConnectedPostCategories {
                      databaseId
                    }
                  }
                }
              }
              practiceToPostsConnection {
                nodes {
                  title
                  uri
                  categories {
                    nodes {
                      databaseId
                    }
                  }
                }
              }
            }
          }
        }
        template {
          ... on WpTemplate_PracticeParent {
            practiceParentConfiguration {
              eaPracticeShortName
              eaPracticeParentColoradoConnectedPostCategories {
                databaseId
              }
            }
          }
        }
      }
    }
  }
`;

BrowseResourcesTemplate.propTypes = {
  data: object,
};

BrowseResourcesTemplate.defaultProps = {
  data: {},
};

export default BrowseResourcesTemplate;
export { Head } from '../components/seo/seo';
