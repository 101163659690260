import { string } from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './resource-main-image.module.scss';

function ResourceMainImage({ title }) {
  return (
    <div className={styles.imageContainer}>
      <StaticImage
        src="../../images/resource-center.jpg"
        alt="Main image"
        width={1200}
        className={styles.img}
      />
      <div className={styles.overlay}>
        <h1>{title}</h1>
      </div>
    </div>
  );
}

ResourceMainImage.propTypes = {
  title: string,
};

ResourceMainImage.defaultProps = {
  title: '',
};

export { ResourceMainImage };
