// extracted by mini-css-extract-plugin
export var articleSvgContainer = "index-module--article-svg-container--7e318";
export var articleSvgContainerActive = "index-module--article-svg-container-active--19075";
export var articleTitle = "index-module--article-title--048b9";
export var articleTitleActive = "index-module--article-title-active--a86b1";
export var articles = "index-module--articles--e77e6";
export var articlesContainer = "index-module--articles-container--9b4a8";
export var childSvgContainer = "index-module--child-svg-container--c2010";
export var childSvgContainerActive = "index-module--child-svg-container-active--afafe";
export var childTitle = "index-module--child-title--fd734";
export var childTitleActive = "index-module--child-title-active--e3bf5";
export var entry = "index-module--entry--5d535";
export var main = "index-module--main--d5849";
export var pageContainer = "index-module--page-container--b68d1";
export var practiceChildContainer = "index-module--practice-child-container--3324d";
export var practiceParentContainer = "index-module--practice-parent-container--90e4d";
export var svgContainer = "index-module--svg-container--b289a";
export var svgContainerActive = "index-module--svg-container-active--6be99";
export var title = "index-module--title--8e565";
export var titleActive = "index-module--title-active--e3b9e";