import { array, object } from 'prop-types';
import { useState } from 'react';
import { Icon } from './icon';
import { isInArray, addNotDuplicatedArrayString } from '../../helpers';
import { PracticeChild } from './practice-child';

import * as styles from './index.module.scss';

function PracticeParentItem({
  data, posts,
}) {
  const allResourcesPerPParent = [];
  let auxPosts = [];
  data.wpChildren.nodes?.forEach((childrenPosts) => {
    childrenPosts.practiceToPostsConnection.nodes?.forEach((post) => {
      if (!isInArray(auxPosts, post.title)) {
        auxPosts = addNotDuplicatedArrayString(auxPosts, post.title);
        allResourcesPerPParent.push(post);
      }
    });
  });
  const [open, setOpen] = useState(false);
  const practiceChilds = data?.wpChildren?.nodes;
  practiceChilds?.sort((a, b) => {
    const shortNameA = a.template?.practiceChildConfiguration?.eaPracticeShortName;
    const shortNameB = b.template?.practiceChildConfiguration?.eaPracticeShortName;
    return (
      shortNameA?.localeCompare(shortNameB));
  });
  return (
    <div className={styles.practiceParentContainer}>
      <button type="button" onClick={() => setOpen(!open)} className={open ? styles.titleActive : styles.title}>
        <Icon
          open={open}
          className={styles.svgContainer}
          classNameActive={styles.svgContainerActive}
        />
        {data.title}
      </button>
      {open && (
      <div>
        {practiceChilds?.map((practiceChild) => (
          <PracticeChild data={practiceChild} key={practiceChild.title} posts={posts} />
        ))}
        <PracticeChild
          data={{ allResourcesPerPParent, title: `ALL ${data.title} RESOURCES` }}
          posts={posts}
          isAllResources
        />
      </div>
      )}
    </div>
  );
}

PracticeParentItem.propTypes = {
  data: object,
  posts: array,
};

PracticeParentItem.defaultProps = {
  data: {},
  posts: [],
};

export { PracticeParentItem };
